var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-select" },
    [
      _c(
        "el-select",
        {
          style: { width: _vm.width },
          attrs: {
            disabled: _vm.disable,
            placeholder: "请选择",
            multiple: _vm.multiple,
          },
          on: { change: _vm.change },
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        _vm._l(_vm.commOptions, function (item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value },
          })
        }),
        1
      ),
      _vm.userInfo.type === "管理员"
        ? _c("span", { staticClass: "addClass", on: { click: _vm.addClass } }, [
            _vm._v("管理" + _vm._s(_vm.extendTitle ? _vm.title : "")),
          ])
        : _vm._e(),
      _c("CommDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          dataColumn: _vm.commOptions,
          title: _vm.title,
          type: _vm.type,
        },
        on: { ok: _vm.cancel, cancel: _vm.cancel, dataChange: _vm.dataChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }