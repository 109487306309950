<template>
  <div class="view-select">
    <el-select
      v-model="selectValue"
      :disabled="disable"
      :style="{width: width}"
      placeholder="请选择"
      :multiple="multiple"
      @change="change"
    >
      <el-option
        v-for="item in commOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <span v-if="userInfo.type === '管理员'" class="addClass" @click="addClass">管理{{ extendTitle ? title : '' }}</span>
    <CommDialog
      :dialogVisible="dialogVisible"
      :dataColumn="commOptions"
      :title="title"
      :type="type"
      @ok="cancel"
      @cancel="cancel"
      @dataChange="dataChange"
    ></CommDialog>
  </div>
</template>

<script>
import { getSysParams } from '@/api/system'
import CommDialog from '../dialog/CommDialog.vue'
import { mapGetters } from 'vuex'
export default {
  components: { CommDialog },
  props: {
    value: {
      type: [Number, String, Object, Array],
      required: true
    },
    disable: {
        type: Boolean,
        default: false
    },
    type: {
        type: Number,
        default: 7
    },
    title: {
        type: String,
        default: '币制'
    },
    width: {
        type: String,
        default: '254px'
    },
    extendTitle: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        selectValue: '',
        dialogVisible: false,
        commData: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    commOptions: function() {
      const arr = []
      this.commData.map((item) => {
        arr.push({
          label: item.description,
          value: item.dbValue
        })
      })
      return arr
    }
  },
  watch: {
    value: {
        handler: function(val) {
                this.selectValue = val
        },
        immediate: true
    }
  },
 created() {
    this.getList()
  },
  methods: {
    async getList() {
        const res = await getSysParams({ type: this.type })
        if (res.success) {
          const arr = []
          res.data &&
            res.data[0].value &&
            res.data[0].value.map((item) => {
              arr.push(item)
            })
          this.commData = arr
        }
    },
    change(value) {
        this.$emit('change', value)
        this.$emit('input', value)
    },
    dataChange() {
      this.getList()
    },
    cancel() {
        this.dialogVisible = false
    },
    addClass() {
        this.dialogVisible = true
    },
    getValueLable(value) {
      return this.commOptions.find(a => a.value === value).label
    }
  }
}
</script>

<style lang="less" scoped>
.view-select {
  width: 100%;
  height: 40px;
  .addClass {
    position: absolute;
    width: 84px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
